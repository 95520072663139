import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
const Header = ({ active }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [goOut, setGoOut] = useState(false)
  return (
    <header>
      <div className="centerwrap clear">
        <div className="logo">
          <Link to="/home">
            <StaticImage
              src={"../images/fluid/alt-logog.png"}
              alt="NETVIDEOGIRLS"
              placeholder="blurred"
              layout="constrained"
              width={680}
            />
          </Link>
        </div>
        <nav className={showMenu ? "show" : ""}>
          <div className="rBtn" onClick={() => setShowMenu(true)}>
            <img src="/images/rBtn.png" alt="" />
          </div>
          <div
            className="rClose"
            onClick={() => {
              setGoOut(true)
              setTimeout(() => {
                setShowMenu(false)
                setGoOut(false)
              }, 320)
              //
            }}
          >
            <img src="/images/close.png" alt="" />
          </div>
          <div className="fullBg"></div>
          <ul className={goOut ? "out" : ""}>
            <li>
              <Link to={`/home`} className={active === "/home" ? "active" : ""}>
                New Girls
              </Link>
            </li>
            <li>
              <Link
                to="/popular-girls"
                className={active === "/popular-girls" ? "active" : ""}
              >
                Popular Girls
              </Link>
            </li>
            <li>
              <a href="https://vip.netvideogirls.com/members/">Member Login</a>
            </li>
            <li className="getBtn">
              <Link to="/join">
                <span>Get Your NetVideoGirls Password</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
