import React, { useEffect, useState } from "react"
import Footer from "./footer"
import Header from "../components/header"
import axios from "axios"
import JoinContext from "../context/join-context"
const Layout = props => {
  const { children, uri } = props
  const [join, setJoin] = useState({})
  useEffect(() => {
    //prod `https://netvideogirls.com/tour-actions/jo.php`
    //dev `http://192.168.86.22:4000/jo`
    axios(`https://netvideogirls.com/tour-actions/jo.php`, {
      headers: {
        "x-xsrf-header":
          "i4GOKD13ZT$TN#3qzVMdzAX3!JV3R5GPv$aFxM8Mz6nO5uKUaGS6Y7X$Cqao8",
      },
    }).then(res => {
      setJoin(res.data)
    })
  }, [])
  return (
    <div>
      <Header active={uri} />
      <JoinContext.Provider value={join}>{children}</JoinContext.Provider>
      <Footer />
    </div>
  )
}
export default Layout
